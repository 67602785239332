<template>
<div class="xz-b">
  <el-row
    class="demand"
    
  >
    <el-col :span="3" class="m-t-32">
      <el-image :src="getUrl(45)"></el-image>
    </el-col>
    <el-col :span="20" :offset="1">
      <el-row>
        <el-col :span="7" class="color-w">
          <p class="text-l">姓名</p>
          <el-input placeholder="请输入姓名" v-model="info.name"></el-input>
          <p class="text-l">手机号</p>
          <el-input
            placeholder="请输入手机号"
            v-model="info.telephone"
          ></el-input>
        </el-col>
        <el-col :span="14" class="color-w" :offset="2">
          <p class="text-l">需求简述</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="请输入需求简述"
            v-model="info.demand"
          ></el-input>
          <el-button type="danger" class="m-t-14 f-l-r" @click="sub"
            >提交需求</el-button
          >
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  </div>
</template>

<script>
export default {
  props: {
    picUrlList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      info: {
        name: "",
        telephone: "",
        demand: "",
      },
    };
  },
  methods: {
    getUrl(id) {
      for (let i = 0; i < this.picUrlList.length; i++) {
        if (this.picUrlList[i].id == id) {
          return this.picUrlList[i].url;
        }
      }
    },
    sub() {
      if (this.info.name == "") {
        this.$message.error("请填写联系人");
        return false;
      }
      if (this.info.telephone == "") {
        this.$message.error("请填写联系手机号");
        return false;
      }
      if (this.info.demand == "") {
        this.$message.error("请填写需求简述");
        return false;
      }
      this.Api.Prise.trainInfo(this.info).then((res) => {
        if (res.status == 200) {
          this.info.name = "";
          this.info.telephone = "";
          this.info.demand = "";
          this.$message.success("提交成功");
        }
      });
    },
  },
};
</script>

<style scoped>
.demand {
  height: 203px;
}
</style>