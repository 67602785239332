<template>
  <div class="min-h-570" :style="{ paddingBottom: advType ? '100px' : '' }">
    <div id="enterpriseTrain">
      <el-image :src="title" style="z-index: 2" class="cu"></el-image>
    </div>
    <el-row class="bg p-t-70 m-t-0120 p-b-50">
      <el-image :src="fourThree"></el-image>
    </el-row>
    <!-- 提交需求 -->
    <div :style="{ background: 'url(' + fourFour + ') no-repeat' }">
      <Xuqiu :picUrlList="picUrlList" id="requery" />
    </div>
    <el-image :src="fourSix" class="m-t-50"></el-image>
    <!-- 选择小筑的4大理由 -->
    <el-row
      :style="{ background: 'url(' + fourSeven + ') no-repeat' }"
      style="height: 777px"
    >
      <el-image :src="fourEight" class="m-t-50"></el-image>
    </el-row>
    <!-- 定制课程 -->
    <el-row
      :style="{ background: 'url(' + fourNine + ') no-repeat' }"
      style="height: 977px"
    >
      <el-image :src="fivety" class="m-t-50"></el-image>
      <div class="m-t-20 xz-b">
        <el-table
          :data="tableData"
          border
          stripe
          :header-cell-style="{
            background: 'linear-gradient(0deg, #25266D 0%, #4543B7 100%)',
            color: '#E1CDA5',
          }"
        >
          <el-table-column label="专业方向" width="104px" align="center">
            <template slot-scope="scoped">
              <p>{{ scoped.row.major }}</p>
            </template>
          </el-table-column>
          <el-table-column label="时长" width="84px" align="center">
            <template slot-scope="scoped">
              <p>{{ scoped.row.time }}</p>
            </template>
          </el-table-column>
          <el-table-column label="培训软件" width="191px">
            <template slot-scope="scoped">
              <el-tooltip placement="top">
                <p v-html="ToBreak(scoped.row.software)" slot="content"></p>
                <p>{{ scoped.row.software }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="使用人群" width="200px">
            <template slot-scope="scoped">
              <el-tooltip placement="top">
                <p v-html="ToBreak(scoped.row.people)" slot="content"></p>
                <p>{{ scoped.row.people }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="BIM技能学习成果">
            <template slot-scope="scoped">
              <el-tooltip placement="top">
                <p v-html="ToBreak(scoped.row.success)" slot="content"></p>
                <p style="margin: 0">{{ scoped.row.success }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="岗位BIM应用价值">
            <template slot-scope="scoped">
              <el-tooltip placement="top">
                <p v-html="ToBreak(scoped.row.value)" slot="content"></p>
                <p style="margin: 0">{{ scoped.row.value }}</p>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
    <!-- BIM总监亲授 -->
    <el-row class="bgT">
      <el-image :src="getUrl(51)" class="m-t-50"></el-image>
      <div class="m-t-20">
        <TeacherItem :teacherList="teacherList" />
      </div>
    </el-row>
    <!-- 案例 -->
    <el-row
      class="bgT"
      style="width: 100%; overflow: hidden; padding-bottom: 50px"
    >
      <el-image :src="getUrl(57)" class="m-t-50"></el-image>
      <Anli :anliList="oneList" class="m-t-50 anLi" ref="anli" />
      <Anli :anliList="twoList" class="m-t-50 anLi" ref="anli" />
    </el-row>
    <!-- 定制服务 -->
    <el-row
      :style="{ background: 'url(' + getUrl(68) + ') no-repeat' }"
      style="height: 963px"
    >
      <el-image :src="getUrl(69)" class="m-t-50"></el-image>
      <div class="m-t-50 xz-b">
        <Customized
          :style="{ background: 'url(' + getUrl(70) + ') no-repeat' }"
        />
      </div>
      <el-col
        :span="24"
        style="margin-top: 125px"
        :style="{ background: 'url(' + fourFour + ') no-repeat' }"
      >
        <Xuqiu :picUrlList="picUrlList" />
      </el-col>
    </el-row>
    <el-image :src="getUrl(71)" class="m-t-50"></el-image>
    <Footer />
    <div class="advPic" :style="{ display: advType ? 'block' : 'none' }">
      <div style="background: #fa8b15; padding-top: 0; padding-bottom: 20px">
        <div class="xz-b">
          <el-row>
            <el-col :span="6">
              <div style="position: relative">
                <el-image
                  :src="eightyOne"
                  style="position: absolute; top: -55px; width: 100%; left: 0"
                ></el-image>
                `
              </div>
            </el-col>
            <el-col :span="17" :offset="1">
              <p class="text-l color-w">
                定制培训方案<i
                  class="el-icon-close color-w f-l-r cu"
                  @click="closeAdv"
                ></i>
              </p>
              <el-input
                placeholder="请输入您的姓名"
                class="f-s-20"
                style="width: 40%"
                v-model="info.name"
              />
              <el-input
                type="number"
                placeholder="请输入您的手机号"
                style="width: 37%; margin-left: 2%"
                class="m-t-5 f-s-20"
                v-model="info.telephone"
              />
              <el-button
                style="width: 18%; margin-left: 2%; background: #e57600"
                class="color-w f-s-20"
                @click="sub"
                >点击提交<i class="el-icon-caret-right"></i
              ></el-button>
            </el-col>
          </el-row>
          <!-- <div class="f-l-r" >
          
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Xuqiu from "@/views/enterprise/xuqiuItem";
import TeacherItem from "@/views/enterprise/teacherItem";
import Anli from "@/views/enterprise/anli";
import Customized from "@/views/enterprise/customized";
export default {
  components: {
    Xuqiu,
    TeacherItem,
    Anli,
    Customized,
  },
  data() {
    return {
      advType: true,
      num: 0,
      picUrlList: [], // 存放拿到的图片url地址
      headImg: "",
      title:require('@/assets/img/enterprise/title.png'),
      fourThree:require('@/assets/img/enterprise/four-three.png'),
      fourFour:require('@/assets/img/enterprise/four-four.png'),
      fourSix:require('@/assets/img/enterprise/four-six.png'),
      fourSeven:require('@/assets/img/enterprise/four-seven.png'),
      fourEight:require('@/assets/img/enterprise/four-eight.png'),
      fourNine:require('@/assets/img/enterprise/four-nine.png'),
      fivety:require('@/assets/img/enterprise/fivety.png'),
      eightyOne:require('@/assets/img/enterprise/eighty-one.png'),
      imgArr: {
        ids: [
          42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58,
          59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 79, 81,
        ],
      },
      tableData: [
        {
          major: "土建",
          time: "8天",
          software: "4款软件\nRevit+Navisworks+lumion+Fuzor",
          people: "土建施工技术人员\n建筑结构设计人员\n项目管理人员",
          success:
            "1.掌握Revit土建相关命令体系\n2.掌握Navisworks动画相关命令\n3.掌握Lumion漫游与渲染图制作\n4.掌握Fuzor制作视频相关命令",
          value:
            "1.计算建筑模型实物量，复核土建设计图\n2.制作复杂构造施工技术动画\n3.制作真实的建筑内外构造宣传短视频或图片\n4.制作建筑物施工流程动画",
        },
        {
          major: "机电",
          time: "6天",
          software: "4款软件\nRevit+Navisworks+lumion+Fuzor",
          people: "机电安装技术人员\n机电设计人员\n项目管理人员",
          success:
            "1.掌握Revit土建相关命令体系\n2.掌握Navisworks碰撞检测、施工动画制作\n3.掌握Lumion漫游与渲染图制作\n4.掌握Fuzor制作施工模拟视频制作",
          value:
            "1.计算建筑设备管道实物量，复核设备管道设计图\n2.管道综合排布，净空分析，复杂节点处施工技术动画\n3.模型深化设计指导现场施工\n4.制作建筑设备工程安装施工流程动画",
        },
        {
          major: "市政路桥",
          time: "9天",
          software:
            "7款软件\nRevit+Dynamo+ Civil3D+Infraworks+Lumion+Fuzor+Navisworks",
          people:
            "道路、桥梁施工技术人员\n地铁、隧道施工人员\n路桥企业项目管理人员",
          success:
            "1.掌握Civil3D创建三维地形及道路中线线性\n2.掌握Revit道路/桥梁建模，配合Dynamo完成复杂建模\n3.掌握AIW系统化展示，整体规划说明等汇报事项\n4.掌握Lumion漫游及效果图输出\n5.掌握Fuzor及Navisworks制作动画能力",
          value:
            "1.制作真实的地形和桥梁模型\n2.掌握向领导系统化展示和汇报整体规划能力\n3.制作真实的桥梁多视角宣传短视频或图片\n4. 完成进度计划推演，重要节点制作参数化工艺动画，满足施工交底及方案优化的需求",
        },
        {
          major: "装配式",
          time: "7天",
          software: "1款软件\nRevit",
          people: "装配式设计、施工人员\n构件厂从业人员\n项目管理人员",
          success:
            "1.掌握Reivit结构相关重点命令\n2.掌握Revit深度参数化族制作方式\n3.掌握Revit深度族样板应用",
          value:
            "1.做好现浇构件模型，以配合预制构件做连接\n2.做好预制构件，并计算工程量，出具预制构件加工图",
        },
        {
          major: "项目管理",
          time: "10天",
          software: "5款软件\nRevit+Navisworks+Lumion+Fuzor+BIM5D",
          people: "施工单位企业管理人员\nBIM中心成员-项目管理人员",
          success:
            "1.理论讲解：企业如何实施BIM、BIM应用点分析、BIM为商务管理赋能、BIM精细化管理\n2.模型深化：指导施工的土建、机电模型深化，模型出图，模型出量，非标准构建-",
          value:
            "1.掌握公司或项目上BIM的流程\n2.掌握公司各部门BIM实施应用点及价值\n3.以管理视角，指导项目BIM技术实施与考核",
        },
      ],
      teacherList: [
        {
          id: 52,
          name: "秦笠洋",
          brief: "筑教育土建BIM独家资...",
          introduce:
            "小筑教育土建BIM独家资深老师，8年国企BIM项目总监，累计培训10万+学员，BIM证书资深考评人 ，具有丰富的施工与设计经验，对BIM技术应用发展和人才培养有独到的见解。",
          url: "",
        },
        {
          id: 53,
          name: "石宇",
          brief: "小筑教育机电BIM实战派...",
          introduce:
            "小筑教育机电BIM实战派资深老师，曾任中建等多家建筑公司BIM负责人，负责过广州第五医院等近10个BIM项目，具备多年总包、分包机电技术施工管理及验收工作经验。",
          url: "",
        },
        {
          id: 55,
          name: "何永强",
          brief: "小筑教育市政路桥BIM资...",
          introduce:
            "小筑教育市政路桥BIM资深老师，曾先后任职于大型建筑国企单位、国内知名BIM咨询公司、国内大型设计院，从事BIM工作6年有余，参与过多次国内大型BIM专项项目，致力于将BIM施工阶段施工过程落实到细节，项目实战经验丰富，教学严谨，深受学员喜爱。",
          url: "",
        },
        {
          id: 54,
          name: "傅玉瑞",
          brief: "小筑教育机特聘老师...",
          introduce:
            "中建系统特聘BIM实战培训老师，曾参与杭州蚂蚁金服等近10个BIM项目的实战管理工作；主持编写5部BIM建模及应用技术教材，理论功底扎实，授课经验丰富，通俗易懂，广受学员一致好评。",
          url: "",
        },
        {
          id: 56,
          name: "杨茂志",
          brief: "小筑教育项目管理...",
          introduce:
            "小筑教育项目管理老师，8年BIM项目负责人经验，曾参与北京新机场南航航食楼项目、北京佛学院项目、北京通州副中心B-2地块项目等近10个项目的BIM技术指导及管理工作。对建模标准制定、BIM项目整体实施过程经验丰富，深受学员喜爱。",
          url: "",
        },
      ],
      anliList: [
        {
          name: "企培-企业案例-中铁二院",
          id: 58,
          url: "",
        },
        {
          name: "企培-企业案例-南京航空航天",
          id: 59,
          url: "",
        },
        {
          name: "企培-企业案例-中建海峡建设",
          id: 60,
          url: "",
        },
        {
          name: "企培-企业案例-兰州交大",
          id: 61,
          url: "",
        },
        {
          name: "企培-企业案例-西安建筑",
          id: 62,
          url: "",
        },
        {
          name: "企培-企业案例-亚泰集团",
          id: 63,
          url: "",
        },
        {
          name: "企培-企业案例-中钢设备集团",
          id: 64,
          url: "",
        },
        {
          name: "企培-企业案例-广东珠江实业",
          id: 65,
          url: "",
        },
        {
          name: "企培-企业案例-宇健集团",
          id: 66,
          url: "",
        },
        {
          name: "企培-企业案例-中建海峡",
          id: 67,
          url: "",
        },
      ],
      oneList: [],
      twoList: [],
      number: 1,
      info: {
        name: "",
        telephone: "",
      },
    };
  },
  created() {
    let Bt = this.$route.query.Bt
    if(this.$f.isNotNull(Bt) && Bt != this.$f.getLocal('Bt')){
      this.$f.setLocal('Bt',Bt)
    }
    this.getPicUrl();
  },
  methods: {
    closeAdv() {
      this.advType = false;
    },
    sub() {
      if (this.info.name == "") {
        this.$message.error("请填写联系人");
        return false;
      }
      if (this.info.telephone == "") {
        this.$message.error("请填写联系手机号");
        return false;
      }
      this.Api.Prise.trainInfo(this.info).then((res) => {
        if (res.status == 200) {
          this.info.name = "";
          this.info.telephone = "";
          this.$alert("提交成功，稍后会有助教老师联系您~", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              console.log(action);
            },
          });
        }
      });
    },
    ToBreak(val) {
      return val.split("\n").join("<br>");
    },
    getPicUrl() {
      // 根据传入的数组获取对应的图片url
      let data = this.imgArr;
      this.Api.Prise.getPicUrl(data).then((res) => {
        this.picUrlList = this.picUrlList.concat(res.data);
        this.teacherList.forEach((item) => {
          for (let i = 0; i < this.picUrlList.length; i++) {
            if (item.id == this.picUrlList[i].id) {
              item.url = this.picUrlList[i].url;
            }
          }
        });
        this.anliList.forEach((item) => {
          for (let i = 0; i < this.picUrlList.length; i++) {
            if (item.id == this.picUrlList[i].id) {
              item.url = this.picUrlList[i].url;
            }
          }
        });
        for (let i = 0; i < 5; i++) {
          for (const key in this.anliList) {
            if (key < 5) {
              this.oneList.push(this.anliList[key]);
            } else {
              this.twoList.push(this.anliList[key]);
            }
          }
        }
      });
    },
    getUrl(id) {
      for (let i = 0; i < this.picUrlList.length; i++) {
        if (this.picUrlList[i].id == id) {
          return this.picUrlList[i].url;
        }
      }
    },
    // subRequir(){
    //   document.getElementById("requery").scrollIntoView()
    // }
  },
  mounted() {
    const s = document.createElement("script");
    s.id = "qd288577119654ce42460b237c9cf94d6b221149a0e2";
    s.charset = "utf-8";
    s.async;
    s.defer;
    s.type = "text/javascript";
    s.src =
      "https://wp.qiye.qq.com/qidian/2885771196/54ce42460b237c9cf94d6b221149a0e2";
    document.body.appendChild(s);
  },
};
</script>

<style scoped>
.advPic >>> .el-input__inner{
  border: 0;
}
/* .advPic .el-input >>> .el-input__inner {
  height: 60px !important;
} */
.advPic {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 9999;
  /* height: 290px; */
}
.anLi {
  transition: left 1s;
}
.el-table .cell {
  white-space: pre-line;
}
</style>
<style scoped>
.bg {
  background-color: #e8e8f0;
}
.el-table .cell.el-tooltip {
  white-space: pre-wrap;
}
.bgT {
  background: #efedf9;
}
</style>