<template>
  <div class="text-l box" @mouseover="mouseover" @mouseleave="mouseleave" :style="{animationPlayState:!active ? 'running' : 'paused'}">
    <el-image
      :src="item.url"
      v-for="(item, i) in anliList"
      :key="i"
      style="width: 300px; margin-left: 10px; margin-right: 10px"
    ></el-image>
  </div>
</template>

<script>
export default {
  props: {
    anliList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
        active : false
    };
  },
  methods: {
    mouseover() {
        this.active = true
    },
    mouseleave(){
        this.active = false
    }
  },
};
</script>

<style scoped>
.activeBox {
  width: 1000%;
}
.box {
  width: 1000%;
  /*动画名称*/
  animation-name: move;
  /*动画时间*/
  animation-duration: 60s;
  /*动画次数*/
  animation-iteration-count: infinite;
  /*匀速*/
  animation-timing-function: linear;
}
@keyframes move {
  /*动画起始位置*/
  0% {
    transform: translateX(0px);
  }
  /*动画结束状态*/
  100% {
    transform: translateX(-3200px);
  }
}
</style>