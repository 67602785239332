<template>
  <div class="xz-b">
    <el-row>
      <el-col
        :span="5"
        v-for="(item, i) in teacherList"
        :key="i"
        style="position: relative; width: 20%"
        class="cu teachcer"
      >
        <el-image :src="item.url"></el-image>
        <div
          style="
            background: #32337c;
            width: 184px;
            height: 76px;
            opacity: 0.8;
            position: absolute;
            bottom: 20px;
            left: 10px;
          "
          class="tO text-l"
        >
          <p
            style="
              font-size: 21px;
              color: #e1cda5;
              margin-top: 5px;
              margin-bottom: 5px;
            "
            class="p-l-10"
          >
            {{ item.name }}
          </p>
          <p style="font-size: 14px; color: white" class="p-l-10">
            {{ item.brief }}
          </p>
        </div>
        <div
          style="
            background: #32337c;
            width: 200px;
            opacity: 0.8;
            position: absolute;
            bottom: 5px;
            height: 300px;
            padding-left: 10px;
            padding-right: 10px;
            left: 10px;
          "
          class="tT"
        >
          <p
            style="
              font-size: 21px;
              color: #e1cda5;
              margin-top: 5px;
              margin-bottom: 5px;
            "
          >
            {{ item.name }}
          </p>
          <p style="font-size: 14px; color: white">
            {{ item.introduce }}
          </p>
        </div>
      </el-col>
    </el-row>

    
  </div>
</template>

<script>
export default {
  props: {
    teacherList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.tT {
  display: none;
}
.teachcer:hover .tO {
  display: none;
  cursor: pointer;
}
.teachcer:hover .tT {
  display: block;
}
</style>