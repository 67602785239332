import { render, staticRenderFns } from "./xuqiuItem.vue?vue&type=template&id=acf00536&scoped=true&"
import script from "./xuqiuItem.vue?vue&type=script&lang=js&"
export * from "./xuqiuItem.vue?vue&type=script&lang=js&"
import style0 from "./xuqiuItem.vue?vue&type=style&index=0&id=acf00536&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf00536",
  null
  
)

export default component.exports