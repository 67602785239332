<template>
  <div style="height: 460px">
    <el-row>
      <el-col :span="8">
        <p
          id="shangLeft"
          :class="active == '1' ? 'nineTextActive' : 'nineText'"
          @click="changeAc(1)"
        >
          线上课程服务方案
        </p>
        <p id="xiaLeft" @click="changeAc(2)" :class="active == '2' ? 'nineTextActive' : 'nineText'" >
          线下课程服务方案
        </p>
      </el-col>
      <div class="text-l" v-if="active == '1'"> 
        <el-col :span="6">
          <div class="f-l-l">
						<p style="font-size: 27px;">服务6步流程</p>
						<div style="width: 54px;height: 8px;background-color: #32337C;margin-top: 10px;"></div>
						<p>·收集信息</p>
						<p>·开通课程</p>
						<p>·添加双师（报名管家、伴学老师）</p>
						<p>·建立专属学习群（和面授一个群）</p>
						<p>·日常主动跟踪督学</p>
						<p>·学习档案记录</p>
					</div>
        </el-col>
        <el-col :span="8">
          <div class="f-l-l" >
						<p style="font-size: 27px;">服务细节</p>
						<div style="width: 54px;height: 8px;background-color: #32337C;margin-top: 10px;"></div>
						<p>1.培训目的：考试+技能实战。</p>
						<p>2.伴学服务：专职伴学老师，主动学习跟踪督导，</p>
						<p>制定个性化学习方案，定期汇报公司。</p>
						<p>3.实时答疑服务：专属学习QQ群，10分钟响应在线答疑。</p>
						<p>4.考试报名服务：图学会考试报名节点提醒，考试报名考点安排。</p>
					</div>
        </el-col>
      </div>
      <div class="text-l" v-else>
        <el-col :span="6">
          <div class="f-l-l">
						<p style="font-size: 27px;">服务4步流程</p>
						<div style="width: 54px;height: 8px;background-color: #32337C;margin-top: 10px;"></div>
						<p>·建立企业专属QQ学习群</p>
						<p>·收集学员作业并批改点评</p>
						<p>·制作并记录每人学习档案</p>
						<p>·结课考核反馈每人学习情况。</p>
					</div>
        </el-col>
        <el-col :span="8">
          <div class="f-l-l">
						<p style="font-size: 27px;">服务细节</p>
						<div style="width: 54px;height: 8px;background-color: #32337C;margin-top: 10px;"></div>
						<p>1.实战教学：基础命令讲解+真实案例实操练习。</p>
						<p>2.练习与测评：两次测评+每日作业。</p>
						<p>3.答疑服务：</p>
						<p>（1）授课现场配备一名助教老师，辅助授课老师进行现场专业答疑。</p>
						<p>（2）学习群内配备专职答疑老师，10分钟响应在线答疑。</p>
						<p>4.学习档案：统计所有参培人员日常作业及测试结果，</p>
						<p>制作学习档案，针对性给出学习评价及建议。</p>
					</div>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "1",
    };
  },
  methods:{
    changeAc(type){
      this.active = type
    }
  }
};
</script>

<style scoped>
.nineText {
  background: white;
  height: 71px;
  line-height: 71px;
  border-radius: 0px 35px 35px 0px;
  padding-left: 20px;
  width: 231px;
  color: black;
  box-shadow: 0px 2px 31px 3px rgba(6, 64, 117, 0.13);
}
.nineTextActive {
  background: #32337c;
  height: 71px;
  line-height: 71px;
  border-radius: 0px 35px 35px 0px;
  padding-left: 20px;
  width: 231px;
  color: #f4e1b8;
}
</style>